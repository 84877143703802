<main
  wrapper
  ngClass.landscape="landscape"
  fxLayout="column"
  fxLayout.landscape
  fxLayoutAlign.landscape="center end"
>
  <app-joyfood-header fxHide.landscape [hasBack]="false"
    >{{ title | translate }}
    <button mat-icon-button right mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </app-joyfood-header>

  <div ngClass.landscape="mat-dialog-container" fxLayout="column">
    <div class="close-wrap">
      <button
        fxHide
        fxShow.landscape
        class="close"
        mat-icon-button
        right
        mat-dialog-close
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div
      fxHide
      fxShow.landscape
      fxLayout
      fxLayoutAlign="space-between center"
      [style.height.px]="32"
    >
      <span class="title">{{ title | translate }}</span>
    </div>

    <div fxLayout fxLayoutGap="16px" class="content-wrap">
      <div class="content" fxLayout="column" fxFlex="1 1 100%">
        <!-- <div class="amt-to-pay" fxLayoutAlign="space-between">
          <b>{{ 'userInfo' | translate }}</b>
          <b></b>
        </div> -->

        <!-- <div separator></div> -->

        <div class="amt-to-pay" fxLayoutAlign="space-between">
          <span>{{ 'userId' | translate }}</span>
          <span>{{ userId }}</span>
        </div>

        <div class="amt-to-pay" fxLayoutAlign="space-between">
          <span>{{ 'userMembership' | translate }}</span>
          <span>{{ gradeNm }}</span>
        </div>

        <div separator></div>

        <div
          class="amt-to-pay"
          fxLayoutAlign="space-between"
          *ngIf="type === 'use'"
        >
          <b>{{ 'remainPayAmt' | translate }}</b>
          <b>{{ amtToPay | currency }}</b>
        </div>

        <div class="amt-to-pay" fxLayoutAlign="space-between">
          <span>{{ 'totalPointAmt' | translate }}</span>
          <span>{{ pointTotalAmt | currency }}</span>
        </div>

        <div class="amt-input" fxLayoutAlign="space-between center">
          <span>{{
            type === 'use'
              ? ('inputPointAmt' | translate)
              : ('pointSave' | translate)
          }}</span>
          <div fxFlex="170px" fxLayoutAlign="end center">
            {{ amt | currency }}
          </div>
        </div>
      </div>
      <div fxHide fxShow.landscape class="keypad">
        <app-keypad type="4x4">
          <input
            type="hidden"
            [ngModel]="amt"
            (ngModelChange)="onKeypadChange($event)"
          />
        </app-keypad>
      </div>
    </div>
    <div fxHide fxShow.landscape fxFlex></div>
    <div fxHide fxShow.landscape fxLayoutGap="8px">
      <button
        fxFlex="auto"
        mat-flat-button
        color="primary"
        [disabled]="!canConfirm"
        (click)="onConfirmClick()"
      >
        {{ 'confirm' | translate }}
      </button>
    </div>
  </div>

  <div class="footer-spacer"></div>

  <div
    fxHide.landscape
    class="footer mat-elevation-z7"
    fxLayout="column"
    fxLayoutGap="16px"
  >
    <app-keypad>
      <input
        type="hidden"
        [ngModel]="amt"
        (ngModelChange)="onKeypadChange($event)"
      />
    </app-keypad>

    <div fxLayoutGap="8px">
      <button
        fxFlex="auto"
        mat-flat-button
        color="primary"
        [disabled]="!canConfirm"
        (click)="onConfirmClick()"
      >
        {{ 'confirm' | translate }}
      </button>
    </div>
  </div>
</main>
