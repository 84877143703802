import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-point-amt',
  templateUrl: './point-amt.component.html',
  styleUrls: ['./point-amt.component.scss'],
})
export class PointAmtComponent implements OnInit {
  title: string;

  userId: string;

  gradeNm: string;

  amtToPay = 0;

  pointTotalAmt = 0;

  amt = 0;

  type: 'use' | 'chrg' = 'use';

  get canConfirm(): boolean {
    return Number(this.amt) > 0;
  }

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) private matDialogData: any,
    @Optional() private matDialogRef: MatDialogRef<PointAmtComponent>
  ) {}

  ngOnInit(): void {
    this.title = this.matDialogData?.title;
    this.amtToPay = this.matDialogData?.amtToPay ?? 0;
    this.pointTotalAmt = this.matDialogData?.pointTotalAmt ?? 0;
    this.userId = this.matDialogData?.userId;
    this.gradeNm = this.matDialogData?.gradeNm;
    this.type = this.matDialogData?.type || this.type;
    this.amt = this.amtToPay;

    if (this.amt > this.pointTotalAmt) {
      this.amt = this.pointTotalAmt;
    }
  }

  onKeypadChange(amt: number): void {
    let amtToInput = 0;
    amtToInput = Number(amt);

    if (this.type === 'use') {
      if (amtToInput > this.pointTotalAmt || amtToInput > this.amtToPay) {
        amtToInput = Math.min(this.pointTotalAmt, this.amtToPay);
      }
    }

    this.amt = amtToInput;
  }

  onConfirmClick(): void {
    this.matDialogRef.close(Number(this.amt));
  }
}
