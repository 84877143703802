<main wrapper fxLayout="column" fxLayoutAlign.landscape="center">
  <app-joyfood-header fxHide.landscape [hasBack]="false"
    >{{ title | translate }}
    <button mat-icon-button right mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </app-joyfood-header>
  <div
    class="content"
    fxLayout="column"
    fxLayoutGap="16px"
    fxLayoutAlign="start center"
    fxLayoutAlign.landscape="center center"
  >
    <button
      fxHide
      fxShow.landscape
      class="close"
      mat-icon-button
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
    <span class="title" fxHide fxShow.landscape>{{ title | translate }}</span>
    <span class="hint">{{ 'qrReaderHint' | translate }}</span>
    <div class="qr-area" fxLayout fxLayoutAlign="center center">
      <mat-spinner *ngIf="!initQR" [diameter]="36"></mat-spinner>
      <zxing-scanner
        [formats]="[$any('QR_CODE')]"
        (autostarted)="onQrStarted()"
        (scanSuccess)="onQrScanned($event)"
        (permissionResponse)="onPermissionResponse($event)"
      ></zxing-scanner>
    </div>
    <div *ngIf="showSerialInputFl">
      <label class="serial-number" for="serialNum">{{
        'MSG.qrManually' | translate
      }}</label>
      <div fxLayout="row" fxLayoutGap="16px">
        <input id="serialNum" [(ngModel)]="serialNum" />
        <button
          id="okBtn"
          mat-stroked-button
          (click)="onClickOk()"
          [disabled]="!serialNum"
          [class.disabled]="!serialNum"
        >
          {{ 'ok' | translate }}
        </button>
      </div>
    </div>
  </div>
</main>
