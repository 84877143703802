import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenavContainer } from '@angular/material/sidenav';
import { EMPTY, Observable, catchError, filter, mergeMap, of, tap } from 'rxjs';
import { GoodsDeliveryService } from 'src/app/repository/goods/goods-delivery.service';
import { GoodsTakeoutService } from 'src/app/repository/goods/goods-takeout.service';
import { IMrhstTrmnl } from 'src/app/repository/mrhst-trmnl/mrhst-trmnl.model';
import { PointHistService } from 'src/app/repository/point-hist/point-hist.service';
import { UserInfoService } from 'src/app/repository/user/user-info.service';
import { DelngAlarmService } from 'src/app/services/delng-alarm.service';
import { PosService } from 'src/app/services/pos.service';
import { UiService } from '../../core/services/ui.service';
import { GoodsGrpService } from '../../repository/goods-grp/goods-grp.service';
import { DialogService } from '../dialog/dialog.service';
import { PointAmtComponent } from '../point-amt/point-amt.component';
import { QrReaderDialogComponent } from '../qr-reader-dialog/qr-reader-dialog.component';

@Component({
  selector: 'app-joyfood-layout',
  templateUrl: './joyfood-layout.component.html',
  styleUrls: ['./joyfood-layout.component.scss'],
})
export class JoyfoodLayoutComponent implements OnInit, AfterViewInit {
  @ViewChild('sideNav') sideNavContainer: MatSidenavContainer;

  mrhstTrmnl$: Observable<IMrhstTrmnl>;

  mrhstId: number;

  audio: HTMLAudioElement;

  isAudioPlaying = false;

  /**
   * 시연용 포인트 적립자 아이디
   */
  userInfoId: number;

  /**
   * 시연용 포인트 적립 카드 아이디
   */
  userCardId: number;

  /**
   * 시연용 포인트 적립자 이름
   */
  pointUsernm: string;

  constructor(
    public uiService: UiService,
    private posService: PosService,
    private goodsTakeoutService: GoodsTakeoutService,
    private goodsDeliveryService: GoodsDeliveryService,
    private goodsGrpService: GoodsGrpService,
    private dialogService: DialogService,
    private delngAlarmService: DelngAlarmService,
    private userInfoService: UserInfoService,
    private pointHistService: PointHistService
  ) {}

  ngOnInit(): void {
    this.mrhstTrmnl$ = this.posService.getMrhstTrmnl$().pipe(
      tap((mrhstTrmnl) => {
        this.mrhstId = mrhstTrmnl.mrhst.id;
        this.delngAlarmService.alarmOn();
      })
    );
  }

  ngAfterViewInit(): void {
    this.uiService.openDrawerEmitter.subscribe(() => {
      this.sideNavContainer.open();
    });
  }

  /**
   * 시연용 포인트 적립
   */
  onPointSaveClick(): void {
    this.dialogService.matDialog
      .open(QrReaderDialogComponent, {
        panelClass: 'fullscreen-modal',
        data: { title: 'point' },
      })
      .beforeClosed()
      .pipe(
        filter((cardNo) => cardNo),
        mergeMap((cardNo) => this.userInfoService.findPage({ cardNo })),
        mergeMap(({ content }) => {
          // 회원
          const [userInfo] = content;
          // 카드
          const userCard = userInfo?.userCard;

          // 카드 없으면
          if (!userCard) {
            // 메시지 표시 후 종료
            this.dialogService.error('MSG.notFoundUserInfo').subscribe();
            return EMPTY;
          }

          return of(userInfo);
        }),
        filter((userInfo) => !!userInfo && !!userInfo.userCard),
        mergeMap((userInfo) => {
          this.userInfoId = userInfo.id;
          this.userCardId = userInfo.userCard.id;
          this.pointUsernm = userInfo.userNm || userInfo.userId;

          return this.dialogService.matDialog
            .open(PointAmtComponent, {
              panelClass: 'amt-input-modal',
              data: {
                title: 'point',
                pointTotalAmt: userInfo.userCard.pointAmt,
                // amtToPay: this.amtToPay,
                userId: userInfo.userId,
                gradeNm: userInfo.grade.gradeNm,
                type: 'chrg',
              },
            })
            .beforeClosed();
        }),
        filter((pointAmt) => !!pointAmt),
        mergeMap((pointAmt: number) => {
          const pointCreateBody = {
            pointAmt,
            pointHistType: 'RSRV',
            userInfo: { id: this.userInfoId },
          };

          return this.pointHistService.create(pointCreateBody);
        }),
        tap(() => {
          this.dialogService.snackbar('MSG.pointSaved');
        }),
        catchError((error: Error) => {
          return this.dialogService.error(error?.message);
        })
      )
      .subscribe();
  }
}
