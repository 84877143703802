import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageRepositoryService } from '../abstract-repository.service';

@Injectable({
  providedIn: 'root',
})
export class UserInfoService extends PageRepositoryService<any> {
  protected baseUri = 'api/userInfo';

  constructor(protected http: HttpClient) {
    super(http);
  }
}
