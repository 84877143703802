import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { environment } from 'src/environments/environment';
import { DialogService } from '../dialog/dialog.service';

export type QRReaderData = {
  title?: string;
  validator?: (value: string) => boolean;
};

@Component({
  selector: 'app-qr-reader-dialog',
  templateUrl: './qr-reader-dialog.component.html',
  styleUrls: ['./qr-reader-dialog.component.scss'],
})
export class QrReaderDialogComponent implements OnInit {
  @ViewChild(ZXingScannerComponent) private scanner: ZXingScannerComponent;

  initQR = false;

  title: string;

  serialNum: string;

  showSerialInputFl = true;

  validator: (value: string) => boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: QRReaderData,
    private matDialogRef: MatDialogRef<QrReaderDialogComponent>,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.title = this.data?.title || 'QR';
    this.validator = this.data?.validator || (() => true);
  }

  onQrStarted(): void {
    this.initQR = true;
    // setInterval(() => {
    //   this.showSerialInputFl = true;
    // }, 5000);
  }

  /**
   * 데이터 스캔 후 유효겅 검증 및 닫기
   * @param value 스캔된 데이터
   */
  onQrScanned(value: string): void {
    // 사전 설정된 검증 로직 있으면 해당 로직 서용
    if (this.validator) {
      if (this.validator(value)) {
        this.close(value);
      } else {
        this.logInvalidValue(value);
      }
      return;
    }
    // 기본 검증 로직: 2로 시작하는 16자리 선불카드 번호
    if (!Number.isNaN(Number.parseInt(value, 10))) {
      if (value.startsWith('2') && value.length === 16) {
        this.close(value);
      }
    } else {
      this.logInvalidValue(value);
    }
  }

  onClickOk(): void {
    this.close(this.serialNum);
  }

  onPermissionResponse(response: any): void {
    if (!response) {
      this.dialogService.error('MSG.qrNotAvailable').subscribe();
    }
  }

  private close(value: any): void {
    // 이후 스캔 이벤트 발생하지 않도록 정지
    this.scanner.scanStop();
    // 코드가 스캐너 초기화 이전부터
    // 영상 입력에 포함되있으면, 스캐너 초기화 후 즉시 닫히는 현상 발생.
    // 스캔된 코드(미리보기)를 확인할 수 있도록 0.5초 후에 닫기
    setTimeout(() => {
      this.matDialogRef.close(value);
    }, 500);
  }

  private logInvalidValue(value: any): void {
    if (!environment.production) {
      console.warn('Invalid string:', value);
    }
  }
}
