import {
  AfterContentInit,
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  NgModel,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'app-keypad',
  templateUrl: './keypad.component.html',
  styleUrls: ['./keypad.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => KeypadComponent),
    },
  ],
})
export class KeypadComponent
  implements OnInit, AfterContentInit, ControlValueAccessor
{
  @ContentChild(FormControlName) formControlName: FormControlName;

  @ContentChild(FormControlDirective) formControl: FormControlDirective;

  @ContentChild(NgModel) ngModel: NgModel;

  @Input() type: '4x3' | '4x4' = '4x3';

  @Input() isCalc = false;

  @Input() isBig: boolean;

  @Output() clickEnter: EventEmitter<void> = new EventEmitter();

  private onChange: any;

  private onTouched: any;

  private disabled: boolean;

  private selectedValue: number;

  private control: FormControl;

  constructor() {}

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    if (!this.isCalc) {
      this.control =
        this.formControl?.control ||
        this.formControlName?.control ||
        this.ngModel?.control;

      if (!this.control) {
        throw new Error('Control이 없습니다.');
      }
    }
  }

  onClick(number: string): void {
    if (!this.isCalc) {
      const { value } = this.control;
      this.control.setValue(`${value || ''}${number}`);
      return;
    }
    // 현재 값이 0이면 0 지우고 입력
    const value = Number.parseInt(`${this.selectedValue || ''}${number}`, 10);
    this.onChange(value);
    this.selectedValue = value;
  }

  onClearClick(): void {
    if (!this.isCalc) {
      this.control.setValue('');
      return;
    }
    this.onChange(0);
    this.selectedValue = 0;
  }

  onBackClick(): void {
    const strValue = this.isCalc
      ? this.selectedValue.toString()
      : this.control.value.toString();
    let value: number;
    if (strValue.length > 1) {
      value = Number.parseInt(strValue.slice(0, -1), 10);
    } else if (strValue.length === 1) {
      value = 0;
    }
    if (this.isCalc) {
      this.onChange(value);
      this.selectedValue = value;
    } else {
      this.control.setValue(value);
    }
  }

  writeValue(obj: number): void {
    this.selectedValue = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
