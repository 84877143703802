<ng-content select="input" ngProjectAs="input"></ng-content>

<div
  gdRows="36px 36px 36px 36px"
  gdRows.landscape="88px 88px 88px 88px"
  gdGap="8px"
  class="keypad"
  *ngIf="type === '4x3'"
>
  <div gdColumns="1fr 1fr 1fr" gdGap="8px">
    <div mat-ripple (click)="onClick('1')">1</div>
    <div mat-ripple (click)="onClick('2')">2</div>
    <div mat-ripple (click)="onClick('3')">3</div>
  </div>
  <div gdColumns="1fr 1fr 1fr" gdGap="8px">
    <div mat-ripple (click)="onClick('4')">4</div>
    <div mat-ripple (click)="onClick('5')">5</div>
    <div mat-ripple (click)="onClick('6')">6</div>
  </div>
  <div gdColumns="1fr 1fr 1fr" gdGap="8px">
    <div mat-ripple (click)="onClick('7')">7</div>
    <div mat-ripple (click)="onClick('8')">8</div>
    <div mat-ripple (click)="onClick('9')">9</div>
  </div>
  <div gdColumns="1fr 1fr 1fr" gdGap="8px">
    <div mat-ripple (click)="onClick('0')">0</div>
    <div mat-ripple (click)="onClick('00')">00</div>
    <div mat-ripple (click)="onClearClick()">CLR</div>
  </div>
</div>

<div gdGap="8px" gdAlignRows="center" class="keypad num" *ngIf="type === '4x4'">
  <div
    gdColumns="1fr 1fr 1fr 1fr"
    gdGap="6px"
    class="key-box"
    [ngClass]="{ big: isBig }"
  >
    <div mat-ripple (click)="onClick('7')">7</div>
    <div mat-ripple (click)="onClick('8')">8</div>
    <div mat-ripple (click)="onClick('9')">9</div>
    <div mat-ripple (click)="onBackClick()">
      <mat-icon>backspace_back</mat-icon>
    </div>
    <div mat-ripple (click)="onClick('4')">4</div>
    <div mat-ripple (click)="onClick('5')">5</div>
    <div mat-ripple (click)="onClick('6')">6</div>
    <div mat-ripple (click)="onClearClick()">C</div>
    <div mat-ripple (click)="onClick('1')">1</div>
    <div mat-ripple (click)="onClick('2')">2</div>
    <div mat-ripple (click)="onClick('3')">3</div>
    <div
      class="enter"
      mat-ripple
      gdRow="3/5"
      gdColumn="4"
      (click)="clickEnter.emit()"
    >
      Ent
    </div>
    <div class="zero" mat-ripple gdColumn="1" (click)="onClick('0')">0</div>
    <div class="zero" mat-ripple gdColumn="2/4" (click)="onClick('00')">00</div>
  </div>
</div>
